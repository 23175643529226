import React from "react"

import Layout from "../layouts/layout"
import Seo from "../components/seo"

import Text from "../components/text"
import Headline from "../components/headline"
import Block from "../components/block"
import Logo from "../components/logo"

const AVGPage = () => (
  <Layout>
    <Seo title="AVG" />
    <Block>
      <Logo />
      <Headline text="AVG" primary />
      <Text>
        <h3>AVG - Allgemeine Vertragsgrundlagen</h3>
      </Text>
    </Block>

    <Block id="text">
      <Text left>
        <p>
          AVG Kommunikationsdesign
          <br />
          1. Allgemeines 1.1 Für alle Verträge über
          Kommunikationsdesign-Leistungen zwischen dem Kommunikationsdesigner
          und dem Auftraggeber gelten ausschließlich die nachfolgenden AVG. Sie
          gelten auch dann, wenn der Auftraggeber Allgemeine
          Geschäftsbedingungen verwendet und diese entgegenstehende oder von den
          hier aufgeführten AVG abweichende Bedingungen enthalten. 1.2 Die AVG
          des Kommunikationsdesigners gelten auch, wenn der
          Kommunikationsdesigner in Kenntnis entgegenstehender oder von den hier
          aufgeführten Bedingungen abweichender Bedingungen des Auftraggebers
          den Auftrag vorbehaltlos ausführt. 1.3 Abweichungen von den hier
          aufgeführten Bedingungen sind nur dann gültig, wenn ihnen der
          Kommunikationsdesigner ausdrücklich schriftlich zustimmt. 2.
          Vertragsgegenstand Der Gegenstand des Vertrages richtet sich nach den
          Individualvereinbarungen der Partei en. Der Kommunikationsdesigner
          schuldet keine Leistungen, die nicht ausdrücklich individuell
          vereinbart wurden. Geschuldet ist die Übergabe der Entwürfe in einer
          Art und Weise, die die Herstellung der sich aus dem
          Vertrags-/Auftragszweck ergebenden Produkte ermöglicht; die Übergabe
          sogenannter »offener« Dateien ist grundsätzlich nicht geschuldet. 3.
          Vergütung 3.1 Sämtliche Leistungen, die der Kommunikationsdesigner für
          den Auftraggeber bringt, sind kostenpflichtig, sofern nicht
          ausdrücklich etwas anderes vereinbart wird. Wünscht der Auftraggeber
          während oder nach Leistungserbringung des Kommunikationsdesigners
          Sonder- und/oder Mehrleistungen des Kommunikationsdesigners, so folgt
          daraus eine ergänzende Vergütungspflicht. Verzögert sich die
          Durchführung des Auftrags aus Gründen, die der Auftraggeber zu
          vertreten hat, so kann der Kommunikationsdesigner eine angemessene
          Erhöhung der Vergütung verlangen. Bei Vorsatz oder grober
          Fahrlässigkeit kann er auch Schadenersatzansprüche geltend machen. Die
          Geltendmachung eines weitergehenden Verzugsschadens bleibt davon
          unberührt. 3.2 Die Vergütung setzt sich vorbehaltlich anderweitiger
          Vereinbarungen aus einem Entwurfshonorar und – soweit eine Nutzung der
          Leistungen vertraglich vorgesehen ist – einem Nutzungshonorar
          zusammen. Das Nutzungshonorar wird nach dem vertraglich vereinbarten
          Nutzungsumfang bestimmt. Weitergehende Nutzungen müssen ergänzend
          bezahlt werden. Vorbehaltlich anderweitiger Vereinbarungen wird die
          Vergütung des Entwurfs- und Nutzungshonorars nach dem jeweils
          aktuellen AGD Vergütungstarif Design berechnet, wie er zwischen der
          Allianz deutscher Designer (AGD) und der Vereinigung Selbstständige
          Design-Studios (SDSt) geschlossen wurde. Der AGD Vergütungstarif
          Design kann jederzeit beim Auftragnehmer angefordert werden. 3.3
          Vorschläge des Auftraggebers bzw. seiner Mitarbeiter oder seine bzw.
          deren sonstige Mitarbeit haben keinen Einfluss auf die Höhe der
          Vergütung. 3.4 Die Vergütungen sind Nettobeträge, die zuzüglich der
          gesetzlichen Mehrwertsteuer zu zahlen sind. 4. Fälligkeit der
          Vergütung, Abnahme, Verzug 4.1 Die Vergütung ist bei Ablieferung des
          Werkes, soweit vertragsgemäß erbracht, fällig. Werden die bestellten
          Arbeiten in Teilen abgenommen, so ist eine entsprechende Teilvergütung
          jeweils bei einer solchen Teilabnahme fällig. Erstreckt sich ein
          Auftrag über einen Zeitraum von mehr als zwei Monaten oder erfordert
          er vom Kommunikationsdesigner finanzielle Vorleistungen, die 25% des
          vereinbarten Honorars übersteigen, so sind angemessene
          Abschlagszahlungen zu leisten, und zwar 1⁄4 der Gesamtvergütung bei
          Auftragserteilung, 1⁄4 nach Fertigstellung von 50% der Arbeiten, 1⁄2
          nach Ablieferung. 4.2 Die Abnahme darf nicht aus
          gestalterisch-künstlerischen Gründen verweigert werden. Im Rahmen des
          Auftrags besteht Gestaltungsfreiheit. Mängelansprüche hinsichtlich der
          künstlerischen Gestaltung sind ausgeschlossen. 4.3 Bei Zahlungsverzug
          kann der Kommunikationsdesigner bei Rechtsgeschäften, an denen ein
          Verbraucher nicht beteiligt ist, Verzugszinsen in Höhe von 9
          Prozentpunkten über dem jeweiligen Basiszinssatz der Europäischen
          Zentralbank p.a., bei Rechtsgeschäften, an denen ein Verbraucher
          beteiligt ist, in Höhe von 5 Prozentpunkten über dem jeweiligen
          Basiszinssatz der Europäischen Zentralbank p.a. verlangen. Die
          Geltendmachung eines nachgewiesenen höheren Schadens bleibt
          vorbehalten. 5. Nutzungsrechte 5.1 Die Entwürfe und Reinzeichnungen
          dürfen nur für den vereinbarten Nutzungsumfang (zeitlich, räumlich und
          inhaltlich) verwendet werden. Jede Nutzung über den vereinbarten
          Nutzungsumfang (zeitlich, räumlich und inhaltlich) hinaus ist in jedem
          Fall durch ein Nutzungshonorar gesondert zu vergüten. Sie ist bei
          rechtlich geschützten Leistungen nicht gestattet und berechtigt den
          Kommunikationsdesigner neben der Forderung eines ergänzenden
          Nutzungshonorar zur Geltendmachung von Unterlassungs- und
          Schadensersatzansprüchen. Jede auch nur teilweise Nachahmung eines
          rechtlich geschützten Entwurfs oder einer rechtlich geschützten
          Reinzeichnung ist unzulässig. Sämtliche Entwürfe, Reinzeichnungen,
          Konzeptionen und sonstige Leistungen des Kommunikationsdesigners
          werden dem Auftraggeber im Sinne des § 18 Abs. 1 UWG anvertraut. Eine
          unbefugte Verwertung oder Mitteilung an Dritte außerhalb der
          vertraglichen Vereinbarung der Parteien ist unzulässig. 5.2 Der
          Kommunikationsdesigner räumt dem Auftraggeber die für den jeweiligen
          Vertragszweck erforderlichen Nutzungsrechte ein. Soweit nichts anderes
          vereinbart ist, wird im Zweifel jeweils nur das einfache Nutzungsrecht
          eingeräumt. 5.3 Jede Übertragung oder Teilübertragung von
          Nutzungsrechten und jede Einräumung von Unterlizenzen bedarf der
          vorherigen schriftlichen Zustimmung des Kommunikationsdesigners. 5.4
          Die Nutzungsrechte gehen Zug um Zug mit der vollständigen Bezahlung
          der Vergütung auf den Auftraggeber über. 5.5 Geschützte Entwürfe und
          Reinzeichnungen dürfen ohne ausdrückliche Einwilligung des
          Kommunikationsdesigners weder im Original noch bei der Reproduktion
          verändert werden. Der Kommunikationsdesigner hat das Recht eine
          Entstellung oder eine andere Beeinträchtigung seiner geschützten
          Entwürfe und Reinzeichnungen zu verbieten, die geeignet ist, seine
          berechtigten geistigen oder persönlichen Interessen an den
          vorgenannten Werkleistungen zu gefährden. 6. Namensnennungspflicht Der
          Kommunikationsdesigner ist auf oder in unmittelbarer Nähe zu den
          Vervielfältigungsstücken und/oder in unmittelbarem Zusammenhang mit
          der öffentlichen Wiedergabe der Leistungen des Kommunikationsdesigners
          namentlich zu nennen, soweit eine Nennung nicht gänzlich branchen-
          unüblich ist. 7. Sonderleistungen, Neben- und Reisekosten 7.1
          Sonderleistungen wie die Umarbeitung oder Änderung von abnahmefähigen
          Entwürfen, Reinzeichnungen, Konzeptionen, das Manuskriptstudium, die
          Drucküberwachung oder zusätzliche Korrekturläufe werden nach dem
          Zeitaufwand entsprechend AGD Vergütungstarif Design in der jeweils
          aktuellen Fassung gesondert berechnet. 7.2 Der Kommunikationsdesigner
          ist nach vorheriger Abstimmung mit dem Auftraggeber berechtigt, die
          zur Auftragserfüllung notwendigen Fremdleistungen im Namen und für
          Rechnung des Auftraggebers zu bestellen. Der Auftraggeber verpflichtet
          sich, dem Kommunikationsdesigner entsprechende Vollmacht zu erteilen.
          7.3 Soweit im Einzelfall nach vorheriger Abstimmung Verträge über
          notwendige Fremdleistungen im Namen und für Rechnung des
          Kommunikationsdesigners ab - geschlossen werden, verpflichtet sich der
          Auftraggeber, den Kommunikationsdesigner im Innenverhältnis von
          sämtlichen Vergütungsansprüchen freizustellen, die sich aus dem
          Vertragsabschluss ergeben. Der Kommunikationsdesigner ist in
          Abweichung zu Ziffer 4.1 berechtigt, diese Kosten in Rechnung zu
          stellen, sobald sie von dem Dritten in Rechnung gestellt werden. 7.4
          Auslagen für notwendige technische Nebenkosten, insbesondere für
          spezielle Materialien, für die Anfertigung von Modellen, Fotos,
          Zwischenaufnahmen, Reproduktionen, Satz und Druck etc. sind nach
          vorheriger Abstimmung vom Auftraggeber zu erstatten. 7.5 Reisekosten
          und Spesen für Reisen, die im Zusammenhang mit dem Auftrag zu
          unternehmen und mit dem Auftraggeber abgesprochen sind, sind vom
          Auftraggeber zu erstatten. 8. Eigentum an Entwürfen und Daten 8.1 An
          Entwürfen und Reinzeichnungen werden nur Nutzungsrechte eingeräumt,
          nicht jedoch das Eigentum übertragen, falls nicht etwas anderes
          vereinbart wurde oder sich aus dem Vertragszweck etwas anderes ergibt.
          8.2 Die Originale sind dem Kommunikationsdesigner nach angemessener
          Frist unbeschädigt zurückzugeben, falls nicht etwas anderes vereinbart
          wurde oder aus dem Vertragszweck sich etwas anderes ergibt. Bei
          Beschädigung oder Verlust hat der Auftraggeber die Kosten zu ersetzen,
          die zur Wiederherstellung der Originale notwendig sind. Die
          Geltendmachung eines weitergehenden Schadens bleibt unberührt. 8.3 Die
          in Erfüllung des Vertrages entstehenden Daten und Dateien verbleiben
          im Eigentum des Kommunikationsdesigners. Dieser ist nicht
          verpflichtet, Daten und Dateien an den Auftraggeber herauszugeben.
          Wünscht der Auftraggeber deren Herausgabe, so ist dies gesondert zu
          vereinbaren und zu vergüten. 8.4 Hat der Kommunikationsdesigner dem
          Auftraggeber Daten und Dateien, insbesondere sogenannte »offene«
          Dateien zur Verfügung gestellt, dürfen diese nur mit vorheriger
          Zustimmung des Designers geändert werden, es sei denn, aus dem
          Vertragszweck ergibt sich etwas anderes. 8.5 Die Versendung sämtlicher
          in Ziffer 8.1 bis 8.4 genannten Gegenstände erfolgt für Rechnung des
          Auftraggebers und, sofern der Auftraggeber kein Verbraucher ist, auf
          Gefahr des Auftraggebers. 9. Korrektur, Produktionsüberwachung,
          Belegexemplare und Eigenwerbung 9.1 Vor Ausführung einer
          Vervielfältigung sind dem Kommunikationsdesigner Korrekturmuster
          vorzulegen. 9.2 Die Produktionsüberwachung durch den
          Kommunikationsdesigner erfolgt nur aufgrund besonderer Vereinbarung.
          9.3 Von allen vervielfältigten Arbeiten überlässt der Auftraggeber dem
          Kommunikationsdesigner bis zu zehn einwandfreie Belegexemplare
          unentgeltlich, falls nicht etwas anderes vereinbart wurde oder aus dem
          Vertragszweck sich etwas anderes ergibt. 9.4 Der
          Kommunikationsdesigner ist berechtigt, diese Muster und sämtliche in
          Erfüllung des Vertrages entstehenden Arbeiten zum Zwecke der
          Eigenwerbung in sämtlichen Medien unter namentlicher Nennung des
          Auftraggebers zu verwenden und im Übrigen auf das Tätigwerden für den
          Auftraggeber hinzuweisen, sofern der Kommunikationsdesigner nicht über
          ein etwaiges entgegenstehendes Geheimhaltungsinteresse des
          Auftraggebers schriftlich in Kenntnis gesetzt wurde. Etwaige Rechte
          Dritter muss der Kommunikationsdesigner für seine Werbezwecke selbst
          einholen. 10. Haftung 10.1 Der Kommunikationsdesigner haftet für
          entstandene Schäden z.B. an ihm überlassenen Vorlagen, Filmen,
          Displays, Layouts etc. nur bei Vorsatz und grober Fahrlässigkeit, es
          sei denn für Schäden aus der Verletzung des Lebens, des Körpers oder
          der Gesundheit; für solche Schäden haftet der Kommunikationsdesigner
          auch bei Fahrlässigkeit. Im Übrigen haftet er für leichte
          Fahrlässigkeit nur, sofern eine Pflicht verletzt wird, deren
          Einhaltung für die Erreichung des Vertragszwecks von besonderer
          Bedeutung ist (Kardinalpflicht). 10.2 Für Aufträge, die im Namen und
          auf Rechnung des Auftraggebers an Dritte erteilt werden, übernimmt der
          Kommunikationsdesigner gegenüber dem Auftraggeber keinerlei Haftung,
          es sei denn, den Kommunikationsdesigner trifft gerade bei der Auswahl
          des Dritten ein Verschulden. Der Kommunikationsdesigner tritt in
          diesen Fällen lediglich als Vermittler auf. 10.3 Der Auftraggeber
          versichert, dass er zur Verwendung aller dem Kommunikationsdesigner
          übergebenen Vorlagen berechtigt ist. Sollte er entgegen dieser
          Versicherung nicht zur Verwendung berechtigt sein, stellt der
          Auftraggeber den Kommunikationsdesigner von allen Ersatzansprüchen
          Dritter frei. 10.4 Der Auftraggeber hat Entwürfe oder Reinzeichnungen
          auf etwaige Mängel (Richtigkeit von Bild, Text, Zahlen etc.) zu
          überprüfen und gegebenenfalls freizugeben. Für solchermaßen vom
          Auftraggeber freigegebene Entwürfe oder Reinzeichnungen entfällt jede
          Haftung des Kommunikationsdesigners für erkennbare Mängel. Dies gilt
          nicht, wenn der Auftraggeber ein Verbraucher ist. 10.5 Beanstandungen
          offensichtlicher Mängel sind innerhalb von 14 Tagen nach Ablieferung
          des Werks schriftlich beim Kommunikationsdesigner geltend zu machen.
          Zur Wahrung der Frist genügt die rechtzeitige Absendung der
          Mängelrüge. Dies gilt nicht, wenn der Auftraggeber ein Verbraucher
          ist. 10.6 Der Auftraggeber ist verpflichtet, die rechtliche
          Zulässigkeit der Entwürfe und sonstigen Arbeiten selbstständig und
          gewissenhaft prüfen zu lassen, bevor er die Entwürfe und sonstigen
          Arbeiten im geschäftlichen Verkehr verwendet. Der Designer haftet
          außer bei Vorsatz und grober Fahrlässigkeit nicht für die rechtliche
          Zulässigkeit seiner Entwürfe und sonstigen Arbeiten. Er wird den
          Auftraggeber auf rechtliche Bedenken hinweisen, soweit sie ihm bekannt
          sind. Für die vom Auftraggeber zu vervielfältigenden und freigegebenen
          Arbeiten entfällt jede weitergehende Haftung des
          Kommunikationsdesigners. 11. Vertragsauflösung Sollte der Auftraggeber
          den Vertrag vorzeitig kündigen, erhält der Kommunikationsdesigner die
          vereinbarte Vergütung, muss sich jedoch ersparte Aufwendungen oder
          durchgeführte oder böswillig unterlassene Ersatzaufträge anrechnen
          lassen (§ 649 BGB). 12. Schlussbestimmungen 12.1 Erfüllungsort und
          Gerichtsstand ist der Sitz des Kommunikationsdesigners, wenn die
          Vertragsparteien Kaufleute, juristische Personen des öffentlichen
          Rechts oder öffentlich-rechtliches Sondervermögen sind, oder
          mindestens eine Partei keinen allgemeinen Gerichtsstand im Inland hat.
          12.2 Es gilt das Recht der Bundesrepublik Deutschland.
        </p>

        <p>
          AVG Webdesign
          <br />
          1. Allgemeines 1.1 Für alle Verträge über Webdesign-Leistungen
          zwischen dem Webdesigner und dem Auftraggeber gelten ausschließlich
          die nachfolgenden AVG. Sie gelten auch dann, wenn der Auftraggeber
          Allgemeine Geschäftsbedingungen verwendet und diese entgegenstehende
          oder von den hier aufgeführten AVG abweichende Bedingungen enthalten.
          1.2 Die AVG des Webdesigners gelten auch, wenn der Webdesigner in
          Kenntnis entgegenstehender oder von den hier aufgeführten Bedingungen
          abweichender Bedingungen des Auftraggebers den Auftrag vorbehaltlos
          ausführt. 1.3 Abweichungen von den hier aufgeführten Bedingungen sind
          nur dann gültig, wenn ihnen der Webdesigner ausdrücklich schriftlich
          zustimmt. 2. Vertragsgegenstand 2.1 Der Gegenstand des Vertrages
          richtet sich nach den Individualvereinbarungen der Parteien. Der
          Webdesigner schuldet keine Leistungen, die nicht ausdrücklich
          individuell vereinbart wurden. 2.2 Geschuldet ist die Gestaltung einer
          Webseite. Die Tätigkeit des Webdesigners umfasst hierbei
          typischerweise die Erarbeitung einer Konzeption, die grafische
          Gestaltung der mit dem Auftraggeber abgestimmten Konzeption sowie die
          technische Umsetzung und ggf. Programmierung nach dem jeweiligen Stand
          der Technik zum Zeitpunkt des Vertragsschlusses. 2.3 Die folgenden
          Leistungen sind gesondert zu vereinbaren und zu vergüten: (1) Die
          dauernde Pflege der Webseite, sowohl technisch als auch inhaltlich
          (d.h. die Vornahme von Updates). (2) Die Überlassung von
          Server-Speicherplatz auf den Serveranlagen des Webdesigners oder
          Dritten (d.h. die Übernahme des Web-Hosting). (3) Die Vornahme einer
          Domain-Verfügbarkeitsrecherche sowie die Domain-Registrierung. (4) Die
          Benennung als administrativer oder technischer Ansprechpartner oder
          Zonenverwalter im Rahmen der Domainverwaltung. (5) Die Übergabe
          unverschlüsselter Dateien, sog. »offene« Dateien. (6) Der Erwerb von
          Lizenzen von Drittanbietern, z.B. für Software, Grafiken, Fotografien
          oder Templates. 3. Vergütung 3.1 Sämtliche Leistungen, die der
          Webdesigner für den Auftraggeber erbringt, sind kostenpflichtig,
          sofern nicht ausdrücklich etwas anderes vereinbart wird. Wünscht der
          Auftraggeber während oder nach Leistungserbringung des Webdesigners
          Sonder- und/oder Mehrleistungen des Webdesigners (vgl. Ziff. 2.3), so
          folgt daraus eine ergänzende Vergütungspflicht. Dies gilt insbesondere
          für die Übergabe sogenannter »offener« Dateien. Verzögert sich die
          Durchführung des Auftrags aus Gründen, die der Auftraggeber zu
          vertreten hat, so kann der Webdesigner eine angemessene Erhöhung der
          Vergütung verlangen. Bei Vorsatz oder grober Fahrlässigkeit kann er
          auch Schadenersatzansprüche geltend machen. Die Geltendmachung eines
          weitergehenden Verzugsschadens bleibt davon unberührt. 3.2 Die
          Vergütung setzt sich vorbehaltlich anderweitiger Vereinbarungen aus
          einem Entwurfshonorar und – soweit eine Nutzung der Leistungen
          vertraglich vorgesehen ist – einem Nutzungshonorar zusammen. Das
          Nutzungshonorar wird nach dem vertraglich vereinbarten Nutzungsumfang
          bestimmt. Weitergehende Nutzungen müssen ergänzend bezahlt werden.
          Vorbehaltlich anderweitiger Vereinbarungen wird die Vergütung des
          Entwurfs- und Nutzungshonorars nach dem jeweils aktuellen AGD
          Vergütungstarif Design berechnet, wie er zwischen der Allianz
          deutscher Designer (AGD) und der Vereinigung Selbstständige
          Design-Studios (SDSt) geschlossen wurde. Der AGD Vergütungstarif
          Design kann jederzeit beim Auftragnehmer angefordert werden. 3.3
          Vorschläge des Auftraggebers bzw. seiner Mitarbeiter oder seine bzw.
          deren sonstige Mitarbeit haben keinen Einfluss auf die Höhe der
          Vergütung. 3.4 Die Vergütungen sind Nettobeträge, die zuzüglich der
          gesetzlichen Mehrwertsteuer zu zahlen sind. 3.5 Lizenzen von
          Drittanbietern, z.B. für Software, Grafiken, Fotografien oder
          Templates, sind nicht Bestandteil der Vergütung und werden gesondert
          abgerechnet, sofern nicht ausdrücklich etwas anderes vereinbart wurde.
          4. Fälligkeit der Vergütung, Abnahme, Verzug 4.1 Die Vergütung ist bei
          Ablieferung des Werkes, soweit vertragsgemäß erbracht, fällig. Werden
          die bestellten Arbeiten in Teilen abgenommen, so ist eine
          entsprechende Teilvergütung jeweils bei einer solchen Teilabnahme
          fällig. Erstreckt sich ein Auftrag über einen Zeitraum von mehr als
          zwei Monaten oder erfordert er vom Webdesigner finanzielle
          Vorleistungen, die 25% des vereinbarten Honorars übersteigen, so sind
          angemessene Abschlagszahlungen zu leisten, und zwar 1⁄4 der
          Gesamtvergütung bei Auftragserteilung, 1⁄4 nach Fertigstellung von 50%
          der Arbeiten, 1⁄2 nach Ablieferung. 4.2 Die Abnahme darf nicht aus
          gestalterisch-künstlerischen Gründen verweigert werden. Im Rahmen des
          Auftrags besteht Gestaltungsfreiheit. Mängelansprüche hinsichtlich der
          künstlerischen Gestaltung sind ausgeschlossen. 4.3 Bei Zahlungsverzug
          kann der Webdesigner bei Rechtsgeschäften, an denen ein Verbraucher
          nicht beteiligt ist, die gesetzliche Verzugspauschale i. H. v. 40,00
          EUR sowie Verzugszinsen in Höhe von 9 Prozentpunkten über dem
          jeweiligen Basiszinssatz der Europäischen Zentralbank p.a., bei
          Rechtsgeschäften, an denen ein Verbraucher beteiligt ist, in Höhe von
          5 Prozentpunkten über dem jeweiligen Basiszinssatz der Europäischen
          Zentralbank p.a. verlangen. Die Geltendmachung eines nachgewiesenen
          höheren Schadens bleibt vorbehalten. 5. Nutzungsrechte 5.1 Die
          Entwürfe und die Satzdatei dürfen nur für den vereinbarten
          Nutzungsumfang (zeitlich, räumlich und inhaltlich) verwendet werden.
          Jede Nutzung über den vereinbarten Nutzungsumfang (zeitlich, räumlich
          und inhaltlich) hinaus ist in jedem Fall durch ein Nutzungshonorar
          gesondert zu vergüten. Sie ist bei rechtlich geschützten Leistungen
          nicht gestattet und berechtigt den Webdesigner neben der Forderung
          eines ergänzenden Nutzungshonorars zur Geltendmachung von
          Unterlassungs- und Schadensersatzansprüchen. Jede auch nur teilweise
          Nachahmung eines rechtlich geschützten Entwurfs oder einer rechtlich
          geschützten Programmierung ist unzulässig. Sämtliche Entwürfe,
          Satzdateien, Konzeptionen, Programmierungen und sonstige Leistungen
          des Webdesigners werden dem Auftraggeber im Sinne des § 18 Abs. 1 UWG
          anvertraut. Eine unbefugte Verwertung oder Mitteilung an Dritte
          außerhalb der vertraglichen Vereinbarung der Parteien ist unzulässig.
          5.2 Der Webdesigner räumt dem Auftraggeber die für den jeweiligen
          Vertragszweck erforderlichen Nutzungsrechte ein. Soweit nichts anderes
          vereinbart ist, wird im Zweifel jeweils nur das einfache Nutzungsrecht
          eingeräumt. 5.3 Jede Übertragung oder Teilübertragung von
          Nutzungsrechten und jede Einräumung von Unterlizenzen bedarf der
          vorherigen schriftlichen Zustimmung des Webdesigners. 5.4 Die
          Nutzungsrechte gehen Zug um Zug mit der vollständigen Bezahlung der
          Vergütung auf den Auftraggeber über. 5.5 Geschützte Entwürfe und
          Programmierungen dürfen ohne ausdrückliche Einwilligung des
          Webdesigners weder im Original noch bei der Reproduktion verändert
          werden. Der Webdesigner hat das Recht eine Entstellung oder eine
          andere Beeinträchtigung seiner geschützten Entwürfe und
          Programmierungen zu verbieten, die geeignet ist, seine berechtigten
          geistigen oder persönlichen Interessen an den vorgenannten
          Werkleistungen zu gefährden. 6. Namensnennungspflicht Der Webdesigner
          ist im Impressum der erstellten Webseite namentlich zu nennen, soweit
          eine Nennung nicht gänzlich branchenunüblich ist. 7. Sonderleistungen
          und Nebenkosten 7.1 Sonderleistungen wie die Umarbeitung oder Änderung
          von abnahmefähigen Entwürfen, Satzdateien, Konzeptionen,
          Programmierungen, etc. werden nach dem Zeitaufwand entsprechend AGD
          Vergütungstarif Design in der jeweils aktuellen Fassung gesondert
          berechnet. 7.2 Der Webdesigner ist nach vorheriger Abstimmung mit dem
          Auftraggeber berechtigt, die zur Auftragserfüllung notwendigen
          Fremdleistungen im Namen und für Rechnung des Auftraggebers zu
          bestellen. Dies gilt insbesondere im Hinblick auf den Erwerb von
          Lizenzen für Software, Grafiken, Fotografien oder Templates sowie das
          Anmieten von Server-Speicherplatz auf Serveranlagen (Web-Hosting). Der
          Auftraggeber verpflichtet sich, dem Webdesigner entsprechende
          Vollmacht zu erteilen. 7.3 Soweit im Einzelfall nach vorheriger
          Abstimmung Verträge über notwendige Fremdleistungen im Namen und für
          Rechnung des Webdesigners abgeschlossen werden, verpflichtet sich der
          Auftraggeber, den Webdesigner im Innenverhältnis von sämtlichen
          Vergütungsansprüchen freizustellen, die sich aus dem Vertragsabschluss
          ergeben. Der Webdesigner ist in Abweichung zu Ziffer 4.1 berechtigt,
          diese Kosten in Rechnung zu stellen, sobald sie von dem Dritten in
          Rechnung gestellt werden. 7.4 Auslagen für notwendige technische
          Nebenkosten, insbesondere für die Anfertigung von Modellen, Fotos,
          Zwischenaufnahmen, Teasern, Web-Hosting etc. sind nach vorheriger
          Abstimmung vom Auftraggeber zu erstatten. 8. Eigentum an Entwürfen und
          Daten 8.1 An Entwürfen und Satzdateien werden nur Nutzungsrechte
          eingeräumt, nicht jedoch das Eigentum übertragen, falls nicht etwas
          anderes vereinbart wurde oder sich aus dem Vertragszweck etwas anderes
          ergibt. 8.2 Die in Erfüllung des Vertrages entstehenden Daten und
          Dateien verbleiben im Eigentum des Webdesigners. Dieser ist nicht
          verpflichtet, sämtliche Daten und Dateien an den Auftraggeber
          herauszugeben. Wünscht der Auftraggeber deren Herausgabe, so ist dies
          gesondert zu vereinbaren und zu vergüten. 8.4 Hat der Webdesigner dem
          Auftraggeber Daten und Dateien, insbesondere sogenannte »offene«
          Dateien zur Verfügung gestellt, dürfen diese nur mit vorheriger
          Zustimmung des Designers geändert werden, es sei denn, aus dem
          Vertragszweck ergibt sich etwas anderes. 9. Eigenwerbung Der
          Webdesigner ist berechtigt, sämtliche in Erfüllung des Vertrages
          entstehenden Arbeiten zum Zwecke der Eigenwerbung in sämtlichen Medien
          unter namentlicher Nennung des Auftraggebers zu verwenden und im
          Übrigen auf das Tätigwerden für den Auftraggeber hinzuweisen, sofern
          der Webdesigner nicht über ein etwaiges entgegenstehendes
          Geheimhaltungsinteresse des Auftraggebers schriftlich in Kenntnis
          gesetzt wurde. Etwaige Rechte Dritter muss der Webdesigner für seine
          Werbezwecke selbst einholen. 10. Haftung 10.1 Der Webdesigner haftet
          für entstandene Schäden z.B. an ihm überlassenen Dateien, Vorlagen,
          Filmen, Displays, Layouts, Satzdateien etc. nur bei Vorsatz und grober
          Fahrlässigkeit, es sei denn für Schäden aus der Verletzung des Lebens,
          des Körpers oder der Gesundheit; für solche Schäden haftet der
          Webdesigner auch bei Fahrlässigkeit. Im Übrigen haftet er für leichte
          Fahrlässigkeit nur, sofern eine Pflicht verletzt wird, deren
          Einhaltung für die Erreichung des Vertragszwecks von besonderer
          Bedeutung ist (Kardinalpflicht). 10.2 Für Aufträge, die im Namen und
          auf Rechnung des Auftraggebers an Dritte erteilt werden, übernimmt der
          Webdesigner gegenüber dem Auftraggeber keinerlei Haftung, es sei denn,
          den Webdesigner trifft gerade bei der Auswahl des Dritten ein
          Verschulden. Der Webdesigner tritt in diesen Fällen lediglich als
          Vermittler auf. 10.3 Der Auftraggeber versichert, dass er zur
          Verwendung aller dem Webdesigner übergebenen Daten und Vorlagen (insb.
          Grafiken, Fotografien oder Templates) berechtigt ist. Sollte er
          entgegen dieser Versicherung nicht zur Verwendung berechtigt sein,
          stellt der Auftraggeber den Webdesigner von allen Ersatzansprüchen
          Dritter frei. 10.4 Der Auftraggeber hat Entwürfe oder Satzdateien auf
          etwaige Mängel (Richtigkeit von Bild, Text, Zahlen etc.) zu überprüfen
          und gegebenenfalls freizugeben. Für solchermaßen vom Auftraggeber
          freigegebene Entwürfe entfällt jede Haftung des Webdesigners für
          erkennbare Mängel. Dies gilt nicht, wenn der Auftraggeber ein
          Verbraucher ist. 10.5 Beanstandungen offensichtlicher Mängel sind
          innerhalb von 14 Tagen nach Ablieferung des Werks schriftlich beim
          Webdesigner geltend zu machen. Zur Wahrung der Frist genügt die
          rechtzeitige Absendung der Mängelrüge. Dies gilt nicht, wenn der
          Auftraggeber ein Verbraucher ist. 10.6 Der Auftraggeber ist
          verpflichtet, die rechtliche Zulässigkeit der Entwürfe und sonstigen
          Arbeiten selbstständig und gewissenhaft prüfen zu lassen, bevor er die
          Entwürfe und sonstigen Arbeiten im geschäftlichen Verkehr verwendet.
          Der Designer haftet außer bei Vorsatz und grober Fahrlässigkeit nicht
          für die rechtliche Zulässigkeit seiner Entwürfe und sonstigen
          Arbeiten. Er wird den Auftraggeber auf rechtliche Bedenken hinweisen,
          soweit sie ihm bekannt sind. 11. Vertragsauflösung Sollte der
          Auftraggeber den Vertrag vorzeitig kündigen, erhält der Webdesigner
          die vereinbarte Vergütung, muss sich jedoch ersparte Aufwendungen oder
          durchgeführte oder böswillig unterlassene Ersatzaufträge anrechnen
          lassen (§ 649 BGB). 12. Schlussbestimmungen 12.1 Erfüllungsort und
          Gerichtsstand ist der Sitz des Webdesigners, wenn die Vertragsparteien
          Kaufleute, juristische Personen des öffentlichen Rechts oder
          öffentlich-rechtliches Sondervermögen sind oder mindestens eine Partei
          keinen allgemeinen Gerichtsstand im Inland hat. 12.2 Es gilt das Recht
          der Bundesrepublik Deutschland.
        </p>
      </Text>
    </Block>
  </Layout>
)

export default AVGPage
